@normal-primary: #2878FF;
@normal-default: #4B5159;
@normal-other: #0E1726;

@font-face {
	font-family: DINCondensed;
	src: url('src/assets/fontfamily/Din-bolds.ttf');
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

html {
	width: 100%;
	height:100%;overflow:auto;margin: 0;
}

* {
	margin: 0;
	padding: 0;
}

body {
	height: 100%;
	margin: 0;
	padding: 0;
	background: #f5f8fa;
	display: flex;
	overflow-x: auto;
	min-width: 1050px;
	font-size: 14px;
	flex-direction: column;
	font-family: PingFang SC SourceHanSansCN-Regular, SourceHanSansCN, MicrosoftYaHeiSemibold, Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow:auto;margin: 0;
}

img {
	vertical-align: middle;
}

#root {
	height: 100%;
}

ul,
li {
	list-style: none;
	margin: 0;
}

em, i {
	font-style: normal;
}


/* 以下是修改antd公共部分ui*/

:global {
	.ant-btn-primary {
		background: @normal-primary;

		&:active,
		&:hover,
		&:focus {
			background: @normal-primary;
		}

	}

	.ant-btn-default,
	.ant-btn-link {

		&:active,
		&:hover {
			color: @normal-primary;
		}
	}

	.ant-btn-link {
		color: @normal-primary;
	}

	.ant-btn-primary.ant-btn-background-ghost {
		color: #2878FF;
		border-color: #2878FF;
		border-radius: 4px;
		line-height: 100%;
	}

	.ant-card {
		background: #FFFFFF;
		box-shadow: 2px 4px 16px rgba(148, 148, 166, 0.05);

		.ant-card-body {
			padding: 12px;
		}

		&.ant-card-bordered {
			border: 0.5px solid #f0f0f0;
		}
	}

	.ant-card-head {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: rgb(40, 120, 255);
		padding: 0 20px;

		.ant-card-head-title {
			width: 100%;
			padding-left: 12px;

			span {
				position: relative;

				&::before {
					content: '';
					position: absolute;
					display: inline-block;
					width: 4px;
					height: 16px;
					left: -12px;
					background: linear-gradient(180deg, #2878FF 0%, rgba(40, 120, 255, 0.4) 100%);
					border-radius: 2px 0px;
					top: 2px;
				}
			}
		}
	}


	.ant-input {
		&:-webkit-autofill {
			background: #fff;

		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0px 1000px rgba(225, 233, 255, 0) inset !important;
		-webkit-transition-delay: 99999s;
		-webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
	}
	// 去掉type number的右边上下箭头
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	}
	//兼容火狐
	input[type='number'] {
	-moz-appearance: textfield;
	}

	.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		background: @normal-primary;

		&::before {
			content: '';
			background-color: transparent;
		}

		&:hover {
			background: @normal-primary
		}
	}

	.ant-radio-group-solid {
		.ant-radio-button-wrapper {
			background: #FAFAFA;
			border: none;

			&:hover {
				color: @normal-default;
			}
		}
	}


	// table
	.ant-table-container {
		.ant-table-thead>tr>th {
			padding: 12px 12px;
			background: #F5f5f5;
		}
		.ant-table-tbody>tr>td {
			padding: 9px 12px;
			line-height: 26px;
			color: rgb(81, 111, 144);

			.ant-btn {
				height: 26px;
			}
		}
	} 

	// 表单 筛选全部对齐
	.ant-form-inline  {
		.ant-form-item {
			width: 28.5%;

			.ant-form-item-control {
				flex: 1 1;
			}
			.ant-form-item-label > label {
				width: 135px;
				position: relative;
				color: #516f90;
			}

			.ant-picker {
				width: 100%;
			}
		}
	}
}