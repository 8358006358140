@--header-bgcolor:#30448D;
.layout-contain {
    height: 100vh;
    width: 100%;
    display: flex;
    padding-top: 60px;

    .side-menu-wrapper {
        background: #fff;
        flex: 0 0 211px;
        border-top: 1px solid #f0f0f0;
        padding-top: 20px;

        :global {
          // 不带子菜单
          .ant-menu-item {
            margin: 0 !important;
            background: #fff;
            line-height: 48px;
            font-weight: bold;

            a:hover {
              color: #0E1726;
            }
            &:not(.ant-menu-horizontal){
              &.ant-menu-item-selected {
                background: linear-gradient(270deg, #F0F4FF 0%, #FFFFFF 100%);
                a {
                  color: #2878FF;
                }

                &::after {
                  width: 6px;
                  height: 36px;
                  background: #2878FF;
                  border-radius: 4px 0px 0px 4px;
                  border-right: none;
                  top: 3px;
                }
              }
            }

            .ant-menu-item.ant-menu-title-content {
              a {
                color: #0E1726;
              }
            }
          }
          // 带子菜单
          .ant-menu-submenu {
            margin: 0 !important;
            background: #fff;
            line-height: 48px;
            font-weight: bold;

            &.ant-menu-submenu-open {
              svg {
                &:last-child {
                    -webkit-transform: translateY(-50%) rotate(-180deg);
                            transform: translateY(-50%) rotate(-180deg);
                }
              }
            }

            &.ant-menu-item-only-child {
              height: 48px;
              line-height: 48px;
            }

            .ant-menu-submenu-title {
              margin: 0;
              height: 48px;
              line-height: 48px;

              &:active {
                background: #fff;
              }
            }

            .ant-menu-sub {
              .ant-menu-item {
                padding-left: 50px;
                height: 48px;
                line-height: 48px;
                span {
                  position: relative;
                  left: 14px;
                }

                

                &.ant-menu-item-selected {
                  background: linear-gradient(270deg, #F0F4FF 0%, #FFFFFF 100%);
                  border-radius: 4px;

                  a {
                    color: #2878FF;
                  }

                  &::after {
                    width: 6px;
                    height: 36px;
                    background: #2878FF;
                    border-radius: 4px 0px 0px 4px;
                    border-right: none;
                    top: 7px;
                  }
                }
              }
            }

            .ant-menu-title-content {
              color: #0E1726 !important;
              font-size: 14px;
            }

            a {
              color: #4B5159;
            }
          }
        }
    }

}

header.layout-header {
    display: inline-flex;
    height: 60px ;
    padding: 0 25px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    background: url('src/assets/header.png') no-repeat center/cover;

    .logo-wrapper {
        font-size: 20px;
        span {
          font-size: 20px;
        }

        a {
          color: #fff;
          font-weight: 600;
        }
        svg {
           vertical-align: middle;
           margin-right: 10px;
           position: relative;
           top: -2px;
        }
    }
    .infomation {
      float: right;
      display: inline-flex;
      align-items: center;

      .brandName {
        color: #fff;
      }

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgb(255, 255, 255);
        margin-left: 12px;
        align-items: center;

        svg {
            width: 30px;
            height: 30px;
            position: relative;
            top: -7px;
        }
      }

      .account {
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        margin-left: 12px;
      }

      .logout {
        float: right;
        margin-left: 30px;
        cursor: pointer;
        margin-top: 3px;
      }
    }
}