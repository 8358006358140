.noData {
    position: relative;
    width: 190px;
    height: 146px;
    background: url(../noData.png) no-repeat center / cover;
    background-size: 100% 100%;
    /* left: 0; */
    /* right: 0; */
    margin: 0 auto;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-top: 28px;
    top: 12px;

    &.tops {
        top: 180px;
        bottom: 0;

    }
    p {
        position: relative;
        top: 10px;
        color: rgba(0, 0, 0, .25);
    }
}

.no-Data-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 148px;
        height: 114px;
    }

    p {
        position: relative;
        font-size: 16px;
        color: #0E1726;
        line-height: 24px;
        top: 16px;
    }
}

.flex {
    display: flex;
}
.item-center {
    align-items: center;
}
.just-center {
    justify-content: center;
}
.metadata-icon {
    width: 14px;
    height: 14px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 8px;
}
.metadata-icon-car {
	background-image: url(../icon/car@2x.png);
}
.metadata-icon-model {
	background-image: url(../icon/model@2x.png);
}
.metadata-icon-actions {
	background-image: url(../icon/actions@2x.png);
}
.metadata-icon-invitations {
	background-image: url(../icon/invitations@2x.png);
}
.metadata-icon-part {
	background-image: url(../icon/part@2x.png);
}
.metadata-icon-attribute {
	background-image: url(../icon/attribute@2x.png);
}
.metadata-icon-discount {
	background-image: url(../icon/discount@2x.png);
}
.metadata-icon-task {
	background-image: url(../icon/task.png);
}
.metadata-icon-data {
	background-image: url(../icon/data.png);
}
.metadata-icon-order {
	background-image: url(../icon/order.png);
}
.metadata-unit {
    width: 60px;
    height: 32px;
    background: rgba(203, 214, 226, 0.2);
    text-align: center;
    line-height: 32px;
    color: rgb(81, 111, 144);
    border: 1px solid #d9d9d9;
    border-left: none;
}
